

























import {
  BTable, BButton, BCard, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import dayjs from 'dayjs';

export default {
  name: "ReportingTemplates",
  components: {
    BRow,
    BCol,
    BTable,
    BButton
  },
  data() {
    return {
      fields: [
        'id',
        'name'
      ],
      items: [

      ]
    }
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      const items = await this.$api.reporting.templates.GetList();
      this.$set(this, 'items', items);
    },
    async goTo(identifier: string) {
      await this.$router.push({
        name: "reporting-template",
        params: {
          identifier
        }
      })
    },
    typeName(type: string) {
      switch (type) {
        case "question_breakdown":
          return "Question Breakdown";
        case "status_breakdown":
          return "Status Breakdown";
      }

      return "";
    }
  },
  computed: {
    itemsByType() {
      const items = this.items;
      const itemsByType = {};
      for (const item of items) {
        if (!itemsByType[item.type]) {
          itemsByType[item.type] = [];
        }
        itemsByType[item.type].push(item);
      }
      return itemsByType;
    }
  }
}
